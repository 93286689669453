.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ant-table-tbody > tr > td {
    min-height: 20px !important;
    padding: 0.3em 0.4em;
    /* background-color: #fafafa; */
}

.ant-table-thead > tr > th {
    height: 5px;
    padding: 0.3em 0.3em;
    font-weight: normal;
    background-color: #ffffff;
    text-align: center;
}

.plan-header {
    height: 120px;
    /* background-color: #6ab5ff; */
    /* background-color: #f4ffff; */
    background: linear-gradient(to bottom, #6ab5ff, #20abed);
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #e0e0e0;
}

.table-div:hover .plan-header {
    transform: scale(1.04);
    border: 1px solid #fff;
    border-radius: 10;
    background: linear-gradient(to bottom, #6ab5ff, #1c5895);
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.table-div:hover h4 {
    color: #fff;
}

.font-16-normal {
    font-size: 16px;
    font-weight: normal;
}

.plan-btn {
    border-radius: 25 !important;
    font-weight: 600;
    border: 1px solid #20abed;
    color: #40a9ff;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
}

.active-plan {
    background: linear-gradient(to bottom, #6ab5ff, #1c5895);
    color: #fefefe;
}

.inactive-plan {
    padding-left: 2em;
    padding-right: 2em;
    background: #ffffff;
    border: 1px solid #1c5895;
    color: #1c5895;
}

.ant-progress-line .ant-progress-outer .ant-progress-inner div.ant-progress-bg {
    border: 1px solid #fff;
}

.ant-table table {
    font-size: 14px;
    padding: 0;
}
.ant-table tr {
    padding: 0;
}

.ant-table thead > tr > th {
    background-color: #ecf0fa !important; /*  ecf0fa e6f3fe  f8fafc */
    padding: 1em;
}

.swal2-container {
    z-index: 2000 !important;
}

.formLabel {
    font-size: 13px !important;
    color: #212121 !important;
}

.asterisk {
    color: red;
    font-size: 16px;
}

.antdSelect {
    width: 100%;
    font-size: 13px !important;
}

.font13 {
    font-size: 13px !important;
}
